section.projects {

    .link-tellmemore {
        display: none !important;
        font-weight: bold;
        align-items: center;
        justify-content: start;
        height: 50px;

        &.alignend
        {
            justify-content: end;
        }

        @include md {
            display: flex !important;
            height: 300px;
            margin-top: 25px;
            //justify-content: end;
        }
    }

    .dark-link {
        color: $font;
    }

    .arrow-tellmemore {
        margin-left: 10px;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
    }

    .margins {
        margin-top: 40px;
        margin-bottom: 40px;

        @include md {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        @include lg {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    .nopadding {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
     }

}
