.contact-content {
    margin-bottom: 15px;
}

.contact-title {
    display: block;
    margin-top: 40px;
}

.collab-margins {
    margin-top: 20px;
    margin-bottom: 20px;
}