html,
#root,
#root > div {
    height: 100%;
}

body {
    font-size: 16px;
    line-height: 27px;
    color: $font;
    overflow-x: hidden;
    height: 100%;
    overflow-y: scroll;
}

#root > div {
    display: flex;
    flex-flow: column;
}

.emptystate {
    background: $white;
    height: 100%;
}

a {
    color: $links;
    text-decoration: none;

    &:hover {
        color: $links-hover;
    }

    &.-alt {
        color: $links-hover;

        &:hover {
            color: $links;
        }
    }
}

.text-align-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.content-wrapper {
    flex: 1;
}

.bg-image {
    height: 45vh;
    background-size: cover !important;
    background-position: center !important;
    margin: 0 0 25px 0;
    width: 100%;

    @include md {
        height: 40vh;
    }

    @include lg {
        height: 40vh;
    }
}

.greybackground {
    background-color: $bg-grey !important;
}

.button {
    display: block;
    color: $white;
    padding: 10px 30px;
    background: $primary;
    width: fit-content;
    transition: 0.2s;

    &:hover {
        transition: 0.2s;
        background: $secondary;
        color: $white;
    }
}

.project-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.cookie-consent-bar {
    //important needed to overwrite styling of package
    background: $white !important;
    box-shadow: 0px 0px 5px #e3e3e3;
    align-items: center !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    height: fit-content !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;

    @include md {
        flex-direction: row !important;
    }

    #rcc-confirm-button {
        //important needed to overwrite styling of package
        background: $primary !important;
        transition: 0.2s;
        padding: 15px 40px !important;
        color: $white !important;
        margin: 0px !important;
        width: 100%;

        &:hover {
            background: $secondary !important;
            transition: 0.2s;
        }
    }

    #rcc-decline-button {
        //important needed to overwrite styling of package
        background: $white !important;
        transition: 0.2s;
        padding: 15px 40px !important;
        border: 1px solid $primary !important;
        color: $font !important;
        margin: 10px 0 0 0 !important;
        width: 100%;

        &:hover {
            transition: 0.2s;
            border: 1px solid $error-text !important;
            color: $error-text !important;
        }
    }

    .cookie-bar {
        padding: 10px;
        width: 100%;

        @include lg {
            width: 80%;
        }

        .cookie-title {
            font-size: 22px;
            display: block;
            font-weight: 600;
            color: $font;
            font-family: 'Source Sans Pro';
            margin-bottom: 10px;
        }

        .cookie-text {
            color: $font;
            font-size: 16px;
        }
    }

    .buttons {
        position: relative;
        padding: 15px;
        flex: none !important;
        margin-right: 15px;
        width: 90%;

        @include md {
            width: 210px;
        }
    }

    .content-wrapper {
        width: 90%;
        flex: none !important;

        @include md {
            width: 60%;
        }
    }
}
