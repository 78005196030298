section.about {
    .bg-image {
        height: 600 !important;
        background-position: center !important;
    }

    .member {
        .photo-container {
            width: 100%;
            height: 400px;
            overflow: hidden;
            margin-top: 10px;
            margin-bottom: 5px;

            @include lg {
                height: 300px;
            }

            .member-photo {
                width: 100%;
                height: 100%;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
                transition: 0.2s;

                &.new {
                    background: $secondary;
                    transition: 0.2s;

                    &:hover {
                        transition: 0.2s;
                        background-image: radial-gradient(#45b3c3, $secondary, $secondary);
                    }
                }
            }
        }

        &:hover .photo-container .member-photo {
            transition: 0.2s;
            transform: scale(1.2);
        }

        .member-name {
            margin: 15px 0 5px 0;
            font-weight: bold;
            font-size: 18px;
            color: $font;
        }

        .member-title {
            font-size: 15px;
            color: $font;
            line-height: 22px;
            font-weight: 400;
        }
    }

    .team-photo {
        width: 100%;
        background-size: cover !important;
        height: 400px;
        margin: 50px 0 0px 0;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }
}
