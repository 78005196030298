section.contact {
    .form-layout {
        background-color: $white;
        position: relative;
        margin-bottom: 150px;

        @include md {
            margin-top: 0px;
        }
    }

    .page-title {
        margin-top: 0px;
    }

    .message-sent-success {
        margin: 10px;
        font-size: 14px;
        color: $success-text;
        position: absolute;
        top: 125px;
        left: 5px;
    }

    .message-status-failed {
        margin: 10px;
        font-size: 14px;
        color: $error-text;

        position: absolute;
        top: 125px;
        left: 5px;
    }

    .messsage-anyfieldsempty {
        margin: 10px;
        font-size: 14px;
        color: $error-text;
        position: absolute;
        top: 125px;
        left: 5px;
    }

    .form-field {
        width: 100%;
        font-size: 14px;
        font-family: 'Source Sans Pro';
        margin-bottom: 10px;

        &:first-child {
            input:first-child {
                margin-right: 20px;
            }
        }
    }

    input {
        width: 100%;
        font-size: 14px;
        line-height: 45px;
        outline: 0;
        border-width: 0 0 2px;
        border-color: #EFEFEF;
    }

    input:focus {
        border-color: $secondary;
    }

    .sendbutton {
        margin-top: 10px;
        width: 100px;
        height: 40px;
        position: absolute;
        right: 10px;
        border: 0px;

        background-color: $secondary;
        transition: all 0.2s ease;

        text-align: center;
        font-size: 16px;
        color: $white;
        font-family: 'Source Sans Pro';

        &:hover {
            filter: brightness(110%);
            transition: all 0.2s ease;
            cursor: pointer;
        }

        &:disabled {
            background-color: $error-text;
        }
    }

    .contact-info {
        display: inline;
        line-height: 25px;
        font-family: 'Source Sans Pro';   
        margin-bottom: 30px;
    }

    .contact-info-link {
        display: block;
    }

    .disclaimer {
        position: absolute;
        right: 15px;
        top: 180px;
        font-size: 10px;
        text-align: end;
        width: 80%;

        @include lg {
            width: 50%;
        }
    }

    .map {
        filter: grayscale(100%);
        background: $bg-grey;   
    }
}