//colors
$white: #fff;
$black: #000;
$primary: #000;
$secondary: #2b9eaf;
$placeholder: #9a959e;

//shadows
$box-shadow: 1px 1px 10px 4px rgba(19, 13, 13, 0.06);
$modal-bg: rgba(0, 0, 0, 0.19);
$light-shadow: 0px ​0px 3px 1px rgba(19, 13, 13, 0.06);
$text-shadow: rgba(0, 0, 0, 0.3);

//texts
$font: #3c3b3b;
$links: $secondary;
$links-hover: $black;
$light-font: #585858;
$light-alt: #606060;
$success-text: #0d964c;
$error-text: #cb0000;
$text-gray: #999999;
$text-dark: #4d4d4d;

//inputfields
$input-label: $primary;
$input-text: $black;
$input-disabled-bg: #fcfcfc;

//bgs
$bg-light: #f3f3f3;
$bg-green: #e0f0e6;
$bg-signup-1: #e0f0e6;
$bg-signup-2: #bedfca;
$divider: #e0f0e6;
$btn-disabled: #dcdcdc;
$bg-grey: #efefef;
