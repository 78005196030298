.menu-wrapper {
    .on-desktop {
        display: none;
        margin-top: 0px;
        text-transform: uppercase;

        @include lg {
            display: initial;
        }

        > a {
            margin-right: 20px;
            color: black;
            font-weight: 600;
            font-family: 'Source Sans Pro';
            font-size: 20px;
            transition: .2s;
        
            &:hover {
                color: $secondary;
                text-decoration: none;
                transition: .2s;
            }

            &:last-child {
                margin-right: 0px;
            }
        }

        .active {
            color: $secondary;
        }   
    }

    .menu-icon {
        width: auto;
        height: auto;
        position: absolute;
        top: 19px;
        right: 25px;

        &:hover {
            cursor: pointer;
        }
    }

    .menu-dark-leftside {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;

        background: rgba(0,0,0,0);
        transition: .3s;
        pointer-events: none;

        &.open {
            background: rgba(0,0,0,0.5);
            transition: .3s;
            pointer-events: inherit;
        }
    }

    .mobile-menu {
        right: -66vw;
        background-color: white;
        position: fixed;
        height: 100%;
        top: 0px;
        bottom: 0px;
        transition: .3s;
        padding: 90px 54px;
        text-transform: uppercase;

        &.open {
            transition: .3s;
            right: 0px;
        }

        .menu-icon-close {
            width: auto;
            height: auto;
            position: absolute;
            top: 19px;
            right: 25px;
    
            &:hover {
                cursor: pointer;
            }
        }

        a {
            margin-right: 20px;
            color: black;
            font-weight: 600;
            font-family: 'Source Sans Pro';
            font-size: 25px;
            line-height: 30px;
            transition: .2s;
        
            &:hover {
                color: $secondary;
                text-decoration: none;
                transition: .2s;
            }
        }

        .active {
            color: $secondary;
        }   
    }
}