section.job-openings {
    .image {
        width: 100%;
        height: 350px;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        margin-top: 25px;
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
            transition: 0.2s;
        }
    }

    .content {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .job-title {
        margin-bottom: 25px;
        margin-top: 10px;
    }
}
