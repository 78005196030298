section.projectbanner {

    .align {
        display:flex;

        &.left {
            order: 1;
        }

        &.right {
            order: 2;
        }
    }

    .block-holder {
        height: 350px;
        overflow: hidden;
        display: flex;
        padding: 20px;

        @include md {
            padding: 20px;
        }
    }

    .bannermargins {
        margin: 5px;

        @include md {
            margin: 20px;
        }
    }

    .content-block {
        flex-direction: column;
        position: relative;
        height: 100%;
        width: 50%;
    }

    .text-block {
        height: 90%;
        display: flex;
        //justify-content: center;
        align-items: center;
    }

    .link {
        height: 10%;
        font-weight: bold;
    }

    .arrow {
        position: absolute;
        height: auto;
        width: auto;
        top: 287px;
        left: 95px;
    }

    .line {
        position: absolute;
        height: auto;
        width: auto;
        top: 303px;
        left: -4px;
    }

    .image-website {
        height: 310px;
        width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        position: absolute;

        &.left {
            left: -20px;
            background-position: left;
        }

        &.right {
            right: -20px;
            background-position: left;
        }
    }

    .image-app {
        height: 310px;
        width: 150px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center;
        position: absolute;

        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;

        &.left {
            left: -12px;

            @include md {
                left: 0px;
            }
        }

        &.right {
            right: -12px;

            @include md {
                right: 0px;
            }
        }

        filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    }
}