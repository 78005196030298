@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700|Source+Sans+Pro:300,400,600,700&display=swap');

body {
    font-family: 'Lato';
    font-size: 14px;
}

h1,
.h1 {
    display: block;
    font-family: 'Source Sans Pro';
    font-size: 50px !important;
    line-height: 50px;
    font-weight: bold;
    color: $font;

    &.-alt {
        color: $secondary;
    }
}

h2,
.h2 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Source Sans Pro';
    color: $font;

    &.-alt {
        color: $secondary;
    }

    @include lg {
        font-size: 30px;
    }
}

h3,
.h3 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    font-family: 'Source Sans Pro';
    color: $font;

    &.-alt {
        color: $secondary;
    }
}

h4,
.h4 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: $secondary;

    &.-alt {
        color: $primary;
    }
}