section.privacypolicy {

    // .content {
    //     font-weight: normal;
    // }

    h2,
    .h2 {
        margin-bottom: 10px;
        color: $primary;
    }

    h3,
    .h3 {
        color: $primary;
    }
}