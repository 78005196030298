header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 998;
}

section.header {
    
        .logo {
            width: 180px;
            height: 40px;
            margin: 5px 0px 0px 12px !important;

            image-rendering: pixelated;
        }

        .top-banner {
            width: 100%;
            height: 90px;
            margin-right: auto;
            margin-left: auto;
            padding: 25px 25px 15px 15px;

            transition: all 0.4s ease;
        }

        .top-banner-sticky {
            width: 100%;
            height: 90px;
            margin-right: auto;
            margin-left: auto;
            padding: 25px 25px 15px 15px;

            box-shadow: 1px 2px 4px rgba(0,0,0,0.2);
            transition: all 0.4s ease;
        }
}