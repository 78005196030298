footer {
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-top: 50px;
    padding-bottom: 10px;
}

section.footer {
    .url {
        font-family: 'Lato';
        font-size: 8px;
        margin-right: 15px;
        color: $font;

        @include md {
            font-size: 11px;
        }

        &:hover {
            color: $secondary;
        }
    }

    .footer-text {
        font-family: 'Lato';
        font-size: 12px;
        font-weight: bold;
    }

    .instagram-logo {
        height: 15px;
        width: 15px;

        @include md {
            height: 25px;
            width: 25px;
        }
    }
}