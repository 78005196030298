$box-shadow-normal: 0px 0px 5px #ccc;
$box-shadow-hover: 0px 0px 5px #ccc;

$border-normal-app: $white;
$border-hover-app: $white;

$border-normal-website: $white;
$border-hover-website: $white;

.project-block-wrapper {
    height: 500px;
    overflow: hidden;
    margin-top: 20px;
    margin-right: 20px;

    &:hover {
        cursor: pointer;
    }

    .project-block {
        height: 500px;
        background-size: cover !important;
        background-position: center !important;

        padding: 20px;
        overflow: hidden;
        transition: 0.5s;
        color: $font;
        background-image: radial-gradient(white, #f6f6f6, #f6f6f6);
    }

    .title {
        font-weight: bold;
        font-size: 30px;
        display: block;
        margin-top: 20px;
        transition: 0.2s;
    }

    .description {
        display: block;
        width: 50%;
        margin-top: 20px;
    }

    .logo {
        width: auto;
        margin-top: 10px;
        image-rendering: -webkit-optimize-contrast;
        transition: 0.2s;
    }

    .work {
        display: block;
        opacity: 0.7;
        margin-top: 5px;

        &.dark {
            color: $black !important;
        }
    }

    &.full {
        width: 100%;

        .title,
        .work {
            text-align: center;
        }

        @include lg {
            .title,
            .work {
                text-align: left;
            }
        }

        .project-block {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-left: 170px;

            .second-image {
                position: absolute;
                width: 170px;
                height: 360px;
                background-color: #f6f6f6;
                background-size: cover;
                background-repeat: no-repeat;
                border: 10px solid $border-normal-app;
                border-radius: 28px;
                image-rendering: -webkit-optimize-contrast;
                box-shadow: $box-shadow-normal;
                transition: 0.2s;
                bottom: 0;
                margin: 10px 0 0 10px;
                left: 310px;
                z-index: 2;
                top: 58px;

                display: none;

                @include lg {
                    display: block;
                }
            }

            .image-app {
                position: relative;
                width: 200px;
                min-width: 200px;
                height: 400px;
                background-size: cover;
                background-color: #f6f6f6;
                background-repeat: no-repeat;
                border: 10px solid $border-normal-app;
                border-radius: 28px;
                image-rendering: -webkit-optimize-contrast;
                box-shadow: $box-shadow-normal;
                transition: 0.2s;
                bottom: 0;
                margin: 5px 0 0 10px;
                left: 0px;
                z-index: 1;
            }

            .image-website {
                position: relative;
                width: 480px;
                height: 310px;
                background-size: cover;
                background-repeat: no-repeat;
                border: 10px solid $border-normal-website;
                image-rendering: -webkit-optimize-contrast;
                box-shadow: $box-shadow-normal;
                transition: 0.2s;
                left: -50px;
                margin: 10px 0 0 10px;
                transform: perspective(200px) rotateY(0deg);
            }

            .content {
                margin-left: 50px;
                flex: 1;
                align-self: center;
                transition: 0.2s;

                &.app {
                    margin-left: 200px;
                }
            }

            &:hover {
                transition: 0.2s;

                .image-app {
                    transform: perspective(400px) rotateY(15deg);
                    transition: 0.2s;
                    border: 10px solid $white;
                    box-shadow: 0px 0px 5px #ccc;
                    left: -20px;
                }

                .second-image {
                    transform: perspective(400px) rotateY(15deg);
                    transition: 0.2s;
                    left: 250px;
                }

                .image-website {
                    transform: perspective(400px) rotateY(15deg);
                    transition: 0.2s;
                    border: 10px solid $white;
                    box-shadow: 0px 0px 5px #ccc;
                }

                &.app .content {
                    // padding-left: 50px;
                }

                .content {
                    transform: scale(1.2);
                    transition: 0.2s;
                }

                .title {
                    color: $secondary;
                }
            }
        }
    }

    &.half {
        width: 100%;

        @include lg {
            width: calc(50% - 20px);
        }

        .title,
        .work {
            text-align: center;
        }

        .image-app {
            position: relative;
            width: 218px;
            height: 450px;
            background-size: contain;
            background-repeat: no-repeat;
            border: 10px solid $border-normal-app;
            border-radius: 28px;
            image-rendering: -webkit-optimize-contrast;
            margin: 0 auto;
            bottom: -25px;
            box-shadow: $box-shadow-normal;
            transition: 0.2s;
        }

        .image-website {
            position: relative;
            width: 460px;
            height: 320px;
            background-size: cover;
            background-repeat: no-repeat;
            border: 10px solid $border-normal-website;
            image-rendering: -webkit-optimize-contrast;
            margin: 0 auto;

            transition: 0.2s;
            top: 25px;
            left: 0px;
            box-shadow: $box-shadow-normal;
            // transform: perspective(200px) rotateY(0deg);
        }

        .project-block {
            &:hover {
                transform: scale(1.1);
                transition: 0.2s;

                .title,
                .logo {
                    transition: 0.2s;
                    margin-top: 65px;
                    color: $secondary;
                }

                .image-app {
                    border: 10px solid $border-hover-app;
                    box-shadow: $box-shadow-hover;
                }

                .image-website {
                    border: 10px solid $border-hover-website;
                }
            }
        }
    }
}
