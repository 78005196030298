.collabs {
    .clients {
        background-color: $bg-grey !important;
        padding: 75px 50px;

        .client-item {
            transition: 0.2s;
            opacity: 1;
            text-align: center;
            margin-bottom: 40px;

            @include lg {
                margin-bottom: 0px;
            }

            &:hover {
                opacity: 0.6;
                transition: 0.2s;
            }

            .image {
                max-height: 100px;
                max-width: 100%;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }
}
