$border-normal-app: $white;
$border-hover-app: $white;

$border-normal-website: $white;
$border-hover-website: #111111;

section.projectdetails {
    .block-holder {
        position: relative;
        width: 100%;

        &:first-child {
            padding-top: 100px;

            @include lg {
                padding-top: 0;
            }
        }
    }

    .slider-bg {
        background-color: #efefef;
        height: 250px;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .image-app {
        height: 550px;
        width: 263px;
        min-width: 246px;

        background-color: #f6f6f6;

        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat;

        filter: drop-shadow(0.35rem 0.35rem 0.4rem #ccc);

        border: 10px solid $border-normal-app;
        border-radius: 28px;
        image-rendering: -webkit-optimize-contrast;

        margin-bottom: 40px;

        &:hover {
            border: 10px solid $border-hover-app;
        }

        @include lg {
            margin-top: 0px;
            margin-bottom: 0px;
            transition: 0.2s;
            transform: perspective(400px) rotateY(-10deg);

            &:hover {
                cursor: crosshair;
                transition: 0.2s;
                transform: scale(1.4);
            }

            &.second {
                transform: perspective(400px) rotateY(10deg);

                &:hover {
                    cursor: crosshair;
                    transition: 0.2s;
                    transform: scale(1.4);
                }
            }
        }
    }

    .image-website {
        height: 275px;
        width: 420px;
        min-width: 420px;

        background-color: #f6f6f6;

        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat;

        filter: drop-shadow(0.35rem 0.35rem 0.4rem #ccc);

        border: 10px solid $border-normal-website;
        image-rendering: -webkit-optimize-contrast;

        margin-bottom: 40px;

        @include lg {
            margin-top: 50px;
            margin-bottom: 50px;
            height: 345px;
            width: 530px;
            min-width: 530px;

            transition: 0.2s;
            transform: perspective(400px) rotateY(-10deg);

            &:hover {
                cursor: crosshair;
                transition: 0.2s;
                transform: scale(1.3);
            }

            &.second {
                transform: perspective(400px) rotateY(10deg);

                &:hover {
                    cursor: crosshair;
                    transition: 0.2s;
                    transform: scale(1.3);
                }
            }
        }
    }

    .text-block {
        width: 60%;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        margin: 0 auto;

        @include lg {
            text-align: left;
            width: 600px;
            padding-left: 150px;
            padding-right: 150px;
        }
    }

    .content {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .appstore-image {
        height: 60px;
        background-size: contain !important;
        background-position: left !important;
        background-repeat: no-repeat;
        width: 150px;
        margin-right: 20px;

        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;

        transition: 0.2s;

        &:hover {
            transition: 0.2s;
            transform: scale(1.1);
        }
    }
}
