section.services {
    .image-left {
        height: 400px;
        background-size: cover !important;
        background-position: center !important;
        margin: -15px 0;
        width: 100%;
    }

    .image-right {
        height: 400px;
        background-size: cover !important;
        background-position: center !important;
        margin: -15px 0;
        width: 100%;
    }

    .textbox {
        padding: 50px;

        @include lg {
            padding: 100px;

            &.right {
                text-align: right;
            }
        }
    }

    .margins {
        margin-top: 40px;
        margin-bottom: 40px;
        --bs-gutter-x: 0;

        @include md {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    .title {
        font-size: 25px !important;
    }
}
